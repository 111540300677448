import { apiBusyState, systemApi } from '@/data'
import { ExtendedComponentInfo } from 'hcosmos-api-data'
import { Ref } from 'vue'
import { useToast } from 'vue-toastification'
import i18n from '@/locales/i18n'

const toast = useToast()
const { t } = i18n.global

function createFetchArms(ref: Ref<ExtendedComponentInfo[]>) {
  return async (revalidate: boolean = false) => {
    if (!revalidate && ref.value.length > 0) {
      return ref.value
    }
    try {
      apiBusyState.system = true
      const arms = await systemApi.listUnattachedArms()
      ref.value = arms
      return arms
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error(t('messages.toast.errorServer'))
      }
      return ref.value
    } finally {
      apiBusyState.system = false
    }
  }
}

export { createFetchArms }